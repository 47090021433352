import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class CanDeactivateGuard<T>  {
    canDeactivate(component: T): boolean | Observable<boolean> {
        // Check for existence of canDeactivate method for determining status
        if (component['canDeactivate'] && typeof component['canDeactivate'] === 'function') {
            return component['canDeactivate']();
        }

        // Check if formRef exists, check form's dirty and submitted state
        if (component['form']['formRef'] && component['form']['formRef']['dirty'] && !component['form']['formRef']['submitted']) {
            return confirm('Are you sure? Any unsaved data will be lost!');
        }

        // No custom method exists, return default
        return true;
    }
}
